<template lang="html">
  <div class="">
    <h1>Connect</h1>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "Connect",
});
</script>

<style lang="css" scoped></style>
